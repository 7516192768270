import axios from 'axios'
import {
	Message,
	MessageBox
} from 'element-ui'
import store from '../store'
//请求地址 
/*第一层if判断生产环境和开发环境*/
if (process.env.NODE_ENV == 'production') {
	// axios.defaults.baseURL="https://digit.vtui365.com/collectionAdmin"
	//axios.defaults.baseURL = "http://zhongshu.zhongshucrm.com/"
	 axios.defaults.baseURL = "http://zhongshu.vtui365.com/"
	// axios.defaults.baseURL = "http://dev.zhongshucrm.com/"
	// axios.defaults.baseURL="http://oppo.vtui365.com/adsense"
} else {
	// axios.defaults.baseURL = "http://zhongshu.zhongshucrm.com/"
	// axios.defaults.baseURL = '/rng' 
	axios.defaults.baseURL = "http://zhongshu.vtui365.com/"
	// axios.defaults.baseURL = 'http://192.168.10.5:9003/'
}
//设置超时时间  
axios.defaults.timeout = 1000000000
// post请求头
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
axios.defaults.withcredentials = true
//请求拦截(请求发出前处理请求)
axios.interceptors.request.use((config) => {
	const token = localStorage.getItem("adminToken");
	if (token) {
		config.headers['Token'] = token;
		config.headers['RequestSource'] = 'Admin';
	}
	return config;
}, (error) => {
	return Promise.reject(error);
});
// 响应拦截器（处理响应数据）
axios.interceptors.response.use(
	(res) => {
		const ret = res.data;
		if (ret.status == 10000) {
			MessageBox.confirm(ret.message, '系统提示', {
				confirmButtonText: '重新登录',
				cancelButtonText: '取消',
				showCancelButton: false,
				type: 'warning'
			}).then(() => {
				localStorage.clear();
				location.href = '/';
			})
			return false
		}
		if (ret.status != 1 && res.config.responseType != 'blob') {
			Message({
				showClose: true,
				message: ret.message,
				type: 'error',
				duration: 2000
			})
			return false
		}
		if (res.status !== 200) {
			MessageBox.confirm(ret.msg, '系统提示', {
				confirmButtonText: '重新登录',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				localStorage.clear();
				location.href = '/admin';
			})

			reject(ret.msg)
		}
		return res
	}, (error) => {

		Message({
			showClose: true,
			message: error.message,
			type: 'error',
			duration: 2000
		})
		return Promise.reject(error);
	}
);
// get请求拼接
function params(obj) {
	let result = '';
	let item;
	for (item in obj) {
		if (obj[item] && String(obj[item])) {
			result += `&${item}=${obj[item]}`;
		}
	}
	if (result) {
		result = '&' + result.slice(1)
	}
	return result
}

// 封装get方法
function get(url, data) {
	let result = '';
	let item;
	for (item in data) {
		if (data[item] && String(data[item])) {
			result += `&${item}=${data[item]}`;
		}
	}
	if (result) {
		result = '?' + result.slice(1)
	}
	url = url + result
	return new Promise((resolve, reject) => {
		axios.get(url).then(res => {
			resolve(res.data);
		}).catch(err => {
			reject(err.data);
		})
	});
}

// 封装post方法
function post(url, params) {
	return new Promise((resolve, reject) => {
		axios.post(url, params).then(res => {
			resolve(res.data.data);
		}).catch(err => {
			reject(err.data);
		})
	});
}

// 封装put方法
function put(url, params) {
	return new Promise((resolve, reject) => {
		axios.put(url, params).then(res => {
			resolve(res.data.data);
		}).catch(err => {
			reject(err.data);
		})
	});
}

// 封装delete方法
function delete1(url, params) {
	return new Promise((resolve, reject) => {
		axios.delete(url, params).then(res => {
			resolve(res.data.data);
		}).catch(err => {
			reject(err.data);
		})
	});
}

//对外接口
export function request({
	url,
	method,
	data
}) {
	if (method == 'get') {
		return get(url, data);
	} else if (method == 'post') {

		return post(url, data);
	} else if (method == 'put') {

		return put(url, data);
	} else if (method == 'delete') {
		return delete1(url, data);
	}
}


export function imageUpload({
	param
}) {
	const url = 'https://dx.iyoutui.cn/adminShop/v2/api-docs/common/uploadFiles'
	// 根据后台需求的数据格式确定headers
	return axios.post(url, params, {
		headers: {
			"content-type": "multipart/form-data"
		}
	})
}
export default request