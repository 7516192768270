import request from '../request'

//用户登录
export function login(params) {
  return request({ url: '/gateway/admin/common/login',method: 'post',data: params})
}

//获取登录信息
// export function info(params) {
//   return request({ url: '/gateway/admin/rbac/roleDetail',method: 'get',data: params})
// }

//登录用户权限树
export function info(params) {
  return request({ url: '/gateway/admin/rbac/permissionListForLogin',method: 'get',data: params})
}

// 登录用户三级权限列表
export function permissionThirdListForLoginApi(params) {
  return request({ url: '/gateway/admin/rbac/permissionThirdListForLogin',method: 'get',data: params})
}

//管理端业务数据
export function shopAchievementForAdmin(params) {
  return request({ url: '/gateway/admin/statistics/shopAchievementForAdmin',method: 'get',data: params})
}

//管理端本年度业绩走势图
export function trendChartAchievementYearForAdmin(params) {
  return request({ url: '/gateway/admin/statistics/trendChartAchievementYearForAdmin',method: 'get',data: params})
}


//门店佣金业绩TOP10
export function rankingStatisticsAmount(params) {
  return request({ url: '/gateway/admin/statistics/rankingStatisticsAmount',method: 'get',data: params})
}

//门店成交量Top10
export function rankingStatisticsNum(params) {
  return request({ url: '/gateway/admin/statistics/rankingStatisticsNum',method: 'get',data: params})
}

//管理端首页门店数据
export function shopIndexInfoForAdmin(params) {
  return request({ url: '/gateway/admin/statistics/shopIndexInfoForAdmin',method: 'get',data: params})
}

//管理端管理数据统计
export function shopIndexManageForAdmin(params) {
  return request({ url: '/gateway/admin/statistics/shopIndexManageForAdmin',method: 'get',data: params})
}



export function regionAchievementList(params) {
  return request({ url: '/gateway/walker/report/regionAchievementList',method: 'get',data: params})
}

export function regionShopRunningData(params) {
  return request({ url: '/gateway/walker/report/regionShopRunningData',method: 'get',data: params})
}

export function shopAchievementsList(params) {
  return request({ url: '/gateway/walker/report/shopAchievementsList',method: 'get',data: params})
}
export function shopCostCollectList(params) {
  return request({ url: '/gateway/walker/report/shopCostCollectList',method: 'get',data: params})
}


export function signReportByRegionForZj(params) {
  return request({ url: '/gateway/walker/report/signReportByRegionForZj',method: 'get',data: params})
}

export function adminUserDetail(params) {
  return request({ url: '/gateway/admin/user/adminUserDetail',method: 'get',data: params})
}

export function shopNumberPanel(params) {
  return request({ url: '/gateway/walker/report/shopNumberPanel',method: 'get',data: params})
}

export function regionShopNumberForAdmin(params) {
  return request({ url: '/gateway/walker/report/regionShopNumberForAdmin',method: 'get',data: params})
}

export function changePass(params) {
  return request({ url: '/gateway/admin/user/changePass',method: 'post',data: params})
}

// 重置经纪人密码
export function resetEmpPassword(params) {
  return request({ url: '/gateway/shop/user/resetEmpPassword',method: 'get',data: params})
}

// 我的兼岗列表
export function userPartListApi(params) {
  return request({ url: '/gateway/admin/user/handle/userPartList',method: 'get',data: params})
}

// 兼岗切换账号	
export function userSwitchLoginApi(params) {
  return request({ url: '/gateway/admin/user/handle/userSwitchLogin',method: 'post',data: params})
}